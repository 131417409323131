import axios, { AxiosInstance } from 'axios'
import { API_URL } from '~/const/env'

const apiClient: AxiosInstance = axios.create({
  // APIのURI
  baseURL: API_URL,
  withCredentials: true,
})

const convertRequestData = (data: Map<string,string>): string => {
  let refData = ''
  data.forEach((val, key) => {
    if(refData.length > 0){
      refData = refData + '&'
    }
    if (val !== undefined) {
      refData = refData + key + '=' + val
    }
  })
  return refData
}

const convertFormData = (data: Map<string,string>): FormData => {
  const formData = new FormData()
  data.forEach((value, key) => {
    formData.append(key, value)
  })
  return formData
}

export default apiClient
export { convertRequestData }
export { convertFormData }
